import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { createContainer } from 'unstated-next'
import { getNodesListQueryKey, useSitesSlugRetrieve } from '@/api/generated/hooks'
import { isServer } from '@/pages/_app'
import DataUtils from '@/utils/design-system/dataUtils'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'

type PromiseNodeNumberMapType = Record<number, string>

const usePromiseNodeNumberSetting = () => {
  const [promiseNodeNumberMap, setPromiseNodeNumberMap] = useState<PromiseNodeNumberMapType>()
  const [promiseNodeNumberUpdatedAt, setPromiseNodeNumberUpdatedAt] = useState<number>()
  const isReady = promiseNodeNumberMap !== undefined

  const getPromiseNodeNumber = (siteId: number | undefined) => {
    if (!promiseNodeNumberMap || siteId === undefined) {
      return undefined
    }
    return promiseNodeNumberMap[siteId]
  }

  const updatePromiseNodeNumber = (siteId: number, promiseNodeNumber: string | undefined) => {
    if (!promiseNodeNumberMap) {
      return false
    }
    const promiseNodeNumberMapNew = DataUtils.deepCopy(promiseNodeNumberMap)
    if (promiseNodeNumber) {
      promiseNodeNumberMapNew[siteId] = promiseNodeNumber
    } else {
      delete promiseNodeNumberMapNew[siteId]
    }
    LocalStorage.setItem(LocalStorageKeyEnum.PromiseNodeNumberMap, promiseNodeNumberMapNew)
    setPromiseNodeNumberMap(promiseNodeNumberMapNew)
    setPromiseNodeNumberUpdatedAt(dayjs().valueOf())
    return true
  }

  const removePromiseNodeNumber = (siteId: number) => {
    if (!promiseNodeNumberMap) {
      return false
    }
    const promiseNodeNumberMapNew = DataUtils.deepCopy(promiseNodeNumberMap)
    delete promiseNodeNumberMapNew[siteId]
    LocalStorage.setItem(LocalStorageKeyEnum.PromiseNodeNumberMap, promiseNodeNumberMapNew)
    setPromiseNodeNumberMap(promiseNodeNumberMapNew)
    setPromiseNodeNumberUpdatedAt(dayjs().valueOf())
    return true
  }

  useEffect(() => {
    try {
      setPromiseNodeNumberMap(
        LocalStorage.getItem<PromiseNodeNumberMapType>(LocalStorageKeyEnum.PromiseNodeNumberMap) || {}
      )
    } catch {
      setPromiseNodeNumberMap({})
    }
    setPromiseNodeNumberUpdatedAt(dayjs().valueOf())
  }, [])

  const siteSlug = isServer ? undefined : LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug) ?? undefined
  const { data: site } = useSitesSlugRetrieve(siteSlug ?? '', {
    query: {
      enabled: !!siteSlug,
      cacheTime: 60 * 60 * 1000
    }
  })

  // promiseNodeNumber를 변경된 형식에 맞춰서 마이그레이션
  // RaemianLeadersone 사이트는 제외
  useEffect(() => {
    if (site && isReady) {
      if (site.slug !== SiteSlugEnum.RaemianLeadersone) {
        const promiseNodeNumber = LocalStorage.getItem<string>(LocalStorageKeyEnum.PromiseNodeNumber)
        if (promiseNodeNumber) {
          updatePromiseNodeNumber(site.id, promiseNodeNumber)
        }
      }
      LocalStorage.removeItem(LocalStorageKeyEnum.PromiseNodeNumber)
    }
  }, [site, isReady])

  const queryClient = useQueryClient()
  useEffect(() => {
    if (site?.id !== undefined) {
      queryClient.invalidateQueries(getNodesListQueryKey())
    }
  }, [site?.id])

  return {
    isReady,
    promiseNodeNumberUpdatedAt,
    getPromiseNodeNumber,
    updatePromiseNodeNumber,
    removePromiseNodeNumber
  }
}

const PromiseNodeNumberContainer = createContainer(usePromiseNodeNumberSetting)

export default PromiseNodeNumberContainer
